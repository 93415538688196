.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #09d3ac;
}

::-webkit-scrollbar {
  display: none;
}
/**************/
body{
  font-family: 'Poppins', sans-serif;
  /* background-color: #140324; */
}

.container-principal{
  width: 70%;
  margin: 0 auto;
  /* text-align: center; */
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container-questions{
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 50px 15px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}

.container-final{
  width: 60%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.title-size{
  font-size: 3.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.title-section{
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 33px;
}

.subtitle-section{
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 20px;
}

.button-contained{
  width: 100%;
  background-color: #000 !important;
  text-transform: none !important;
  font-size: 19px !important;
}

.button-contained:hover{
  background-color: rgb(82, 82, 82) !important;
}

.button-contained:disabled{
  background-color: rgb(153, 153, 153) !important;
}

.button-outlined{
  width: 100%;
  color: #000;
  text-transform: none !important;
  font-size: 19px;
  border: solid 2px;
  border-color: rgb(0, 0, 0);
  border-radius: 5px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
}

.button-outlined:hover{
  border-color: rgb(82, 82, 82);
  color: rgb(82, 82, 82);
}

.button-outlined:disabled{
  border-color: rgb(153, 153, 153);
  color: rgb(153, 153, 153);
}

.div-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/**************PROGRESS*****************/
progress[value] {
  display: block;
  width: 100%;
  min-height: 48px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 5px;
  background-color: rgba(149, 250, 61, 0.1);
  /* border: 1px solid #ccc; */
  animation: colorrush 4s infinite linear;
}
progress[value]::-webkit-progress-inner-element {
  border-radius: 5px;
  overflow: hidden;
  background-position: inherit;
}
progress[value]::-webkit-progress-bar {
  border-radius: 5px;
  background-color: transparent;
  background-position: inherit;
}
progress[value]::-webkit-progress-value {
  border-radius: 5px 0 0 5px / 5px 0 0 5px;
  box-shadow: inset 2px 2px 2px rgba(90, 90, 92, 0.2);
  background: repeating-linear-gradient(90deg, transparent, transparent), linear-gradient(#00d774, #00d774, #00d774);
  background-size: calc(12px/0.707) 100%, /* 0.707 = cos(45deg)*/
  100% 800%;
  background-position: inherit;
}
progress[value]::-moz-progress-bar {
  border-radius: 5px 0 0 5px / 5px 0 0 5px;
  box-shadow: inset 2px 2px 2px rgba(90, 90, 92, 0.2);
  background: repeating-linear-gradient(90deg, transparent, transparent), linear-gradient(#00d774, #00d774, #00d774);
  background-size: calc(12px/0.707) 100%, /* 0.707 = cos(45deg)*/
  100% 800%;
  background-position: inherit;
}

@keyframes colorrush {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(10*(12px/0.707)) 100%;
  }
}
/**************/


/**************/
@media screen and (min-width: 481px) and (max-width: 959px) {
  .container-principal{
    width: 90%;
  }
  .title-size{
    font-size: 3.2em;
  }
  
  .container-questions{
    width: 80%;
  }

  .container-final{
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .container-principal{
    width: 100%;
  }
  .title-size{
    font-size: 1.5em;
  }

  .container-questions{
    width: 90%;
  }

  .container-final{
    width: 100%;
  }  
}